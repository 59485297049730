
// window.$ = $;
window.jQuery = require('../../vendor/components/jquery/jquery');
window.$ = window.jQuery;

window.bootstrap = require('../../vendor/twbs/bootstrap/dist/js/bootstrap.bundle');

require('../../vendor/select2/select2/dist/js/select2');

require('./bootstrap');

